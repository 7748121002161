import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
	ToggleChat: "ToggleChat",
	ToggleExpandedView: "ToggleExpandedView",
	UpdateChatList: "UpdateChatList",
};

const initialAuthState = {
	toggleChat: false,
	toggleExpandedView: false,
	chatList: []
};

export const reducer = persistReducer(
	{ storage, key: "ai-chat", whitelist: ["toggleChat", "chatList", "toggleExpandedView"] },
	(state = initialAuthState, action) => {
		switch (action.type) {
			case actionTypes.ToggleChat: {
				return {
					...state,
					toggleChat: !state.toggleChat
				};
			}

			case actionTypes.ToggleExpandedView: {
				return {
					...state,
					toggleExpandedView: !state.toggleExpandedView
				};
			}

			case actionTypes.UpdateChatList: {
				return {
					...state,
					chatList: action.payload
				};
			}

			default:
				return state;
		}
	}
);

export const actions = {
	toggleChat: payload => ({ type: actionTypes.ToggleChat, payload }),
	updateChatList: payload => ({ type: actionTypes.UpdateChatList, payload }),
	toggleExpandedView: payload => ({ type: actionTypes.ToggleExpandedView, payload }),
};

export function* saga() {
}