import React from "react";
import { connect } from "react-redux";
import * as aiChatDuck from "../../../app/store/ducks/ai-chat.duck";
import { toAbsoluteUrl } from "../../../_metronic";

class AIChat extends React.Component {
	render() {
		const { user, aiChat } = this.props;

		const showChatList = (e) => {
			e.preventDefault();
		};

		const expandView = (e) => {
			e.preventDefault();
			this.props.toggleExpandedView();
		};

		const closeChat = (e) => {
			e.preventDefault();
			this.props.toggleChat();
		};

		return (
			<>
				{aiChat.toggleChat ?
					<div className="ai-chat-container">
						<div className={`ai-chat-container-content ${aiChat.toggleExpandedView ? `ai-chat-container-content-expanded` : ``}`}>
							<div className="ai-chat-options">
								<div className="ai-chat-options-btns-left">
									<button className="btn-chat-options" onClick={(e) => showChatList(e)}>
										<img src={toAbsoluteUrl("/images/ai/menu.svg")} style={{ height: '100%', width: 'auto' }} />
									</button>

									<button className="btn-chat-options" onClick={(e) => expandView(e)}>
										<img src={toAbsoluteUrl("/images/ai/expand.svg")} style={{ height: '100%', width: 'auto' }} />
									</button>
								</div>

								<div className="ai-chat-options-btns-right">
									<button className="btn-chat-options" onClick={(e) => closeChat(e)}>
										<img src={toAbsoluteUrl("/images/ai/close.svg")} style={{ height: '100%', width: 'auto' }} />
									</button>
								</div>
							</div>
						</div>
					</div>
					:
					null
				}
			</>
		);
	}
}

const mapStateToProps = ({ auth: { user }, aiChat }) => ({ user, aiChat });

export default connect(mapStateToProps, aiChatDuck.actions)(AIChat);