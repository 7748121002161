import React from "react";
import UserProfile from "../../../app/partials/layout/UserProfile";
import { toAbsoluteUrl } from "../../../_metronic";
import { connect } from "react-redux";
import * as aiChat from "../../../app/store/ducks/ai-chat.duck";

class Topbar extends React.Component {
	render() {
		const { user } = this.props;

		const chatToggle = (e) => {
			e.preventDefault();

			this.props.toggleChat();
		};

		return (
			<div className="kt-header__topbar" style={{ zIndex: '-1' }}>
				{/* // TODO - Remover condicional abaixo após colocar em prod. Ela só mostra o botão pra usuários cujo ID está dentro do array */}
				{[2].includes(user?.id) ?
					<button className="btn-ai-header" onClick={(e) => chatToggle(e)}>
						<img src={toAbsoluteUrl("/images/ai/sparkles.svg")} style={{ maxWidth: '100%', height: 'auto' }} />
					</button>
					: null
				}

				<UserProfile showAvatar={false} showHi={true} showBadge={false} />
			</div>
		);
	}
}

const mapStateToProps = ({ auth: { user } }) => ({ user });

export default connect(mapStateToProps, aiChat.actions)(Topbar);